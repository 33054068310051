import { padding, transparentize } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

import { percentCol } from '../../theme/helpers/grid'
import { getColor } from '../../theme/selectors'
import { Heading } from '../text/Heading'

interface ScrollerProps {
  totalPosts: number
}

interface PostListProps extends ScrollerProps {}

const Wrapper = styled.div`
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  ${padding('4.444444444vw', percentCol(2), '8.333333333vw')}
`

const Scroller = styled.div<ScrollerProps>`
  display: flex;
  height: 100%;
`

const Gradient = styled.div`
  position: absolute;
  width: ${percentCol(4)};
  top: 0;
  right: 0;
  bottom: 0;
  background-image: ${(props) => `linear-gradient(
    to left,
    ${getColor('white')(props)} 0%,
    ${transparentize(1, getColor('white')(props))} 100%
  )`};
  pointer-events: none;
`

const StyledTitle = styled(Heading)`
  text-align: center;
  padding-top: 5vw;
  color: ${getColor('secondary')};
`

const Title: React.FC = (props) => (
  <StyledTitle level="h5" size="sm" {...props} />
)

const Container = styled.div`
  background-color: ${getColor('white')};
`

export const PostList: React.FC<PostListProps> = function ({
  children,
  totalPosts,
}) {
  return (
    <Container>
      <Title>Poursuivez votre exploration</Title>
      <Wrapper>
        <Scroller totalPosts={totalPosts}>{children}</Scroller>
      </Wrapper>
      <Gradient />
    </Container>
  )
}
