import * as React from 'react'

import { PostExcerpt } from '../../../types'
import { HorizontalPostFeature } from './HorizontalPostFeature'
import { VerticalPostFeature } from './VerticalPostFeature'

interface PostFeatureProps {
  className?: string
  layout: 'horizontal' | 'vertical'
  post: PostExcerpt
}

export const PostFeature: React.FC<PostFeatureProps> = function ({
  layout,
  ...props
}) {
  switch (layout) {
    case 'horizontal':
      return <HorizontalPostFeature {...props} />
    case 'vertical':
      return <VerticalPostFeature {...props} />
  }
}
