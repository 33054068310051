import { graphql } from 'gatsby'
import * as React from 'react'

import { PostBySlugQuery } from '../../types/graphql-types'
import { createPost, createPostExcerpt } from '../adapters/post'
import { Layout } from '../components/Layout'
import { Post } from '../components/Post'
import { PostFeature } from '../components/PostFeature'
import { PostList } from '../components/PostList'
import { SEO } from '../components/SEO'
import { createGetPostCategory } from '../selectors/postCategory'

interface PostTemplateProps {
  data: PostBySlugQuery
}

const PostTemplate: React.FC<PostTemplateProps> = ({
  data: {
    allPrismicPost,
    allPrismicPostCategory,
    prismicPost,
    site: {
      siteMetadata: { defaultTitle, description, siteUrl },
    },
  },
}) => {
  const getPostCategory = createGetPostCategory(allPrismicPostCategory)
  const postCategory = getPostCategory(prismicPost.data.category.id)
  const post = createPost(prismicPost, postCategory)

  return (
    <Layout type="post">
      <SEO
        title={post.title}
        description={post.excerpt}
        og={{
          'og:type': 'article',
          'og:title': `${post.title} • ${defaultTitle}`,
          'og:description': post.excerpt || description,
          'og:url': `${siteUrl}/${post.uid}`,
          'og:image': post.image.fixed.src,
          'og:image:alt': post.image.alt || undefined,
        }}
      />
      <Post post={post} />
      <PostList totalPosts={allPrismicPost.edges.length}>
        {allPrismicPost.edges.map(({ node }, index) => (
          <PostFeature
            key={`${node.uid}-${index}`}
            layout="horizontal"
            post={createPostExcerpt(
              node,
              getPostCategory(node.data.category.id),
            )}
          />
        ))}
      </PostList>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicPost(uid: { eq: $uid }) {
      uid
      first_publication_date
      data {
        category {
          id
        }
        content {
          html
        }
        excerpt {
          text
        }
        title {
          text
        }
        image {
          alt
          copyright
          fluid(maxWidth: 1440) {
            aspectRatio
            sizes
            src
            srcSet
            srcSetWebp
          }
          fixed(width: 1200, height: 630) {
            src
          }
        }
      }
    }
    allPrismicPost(filter: { uid: { ne: $uid } }) {
      edges {
        node {
          uid
          first_publication_date
          data {
            category {
              id
            }
            excerpt {
              text
            }
            title {
              text
            }
            image {
              alt
              copyright
              fluid(maxWidth: 720) {
                aspectRatio
                sizes
                src
                srcSet
                srcSetWebp
              }
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
      }
    }
    allPrismicPostCategory {
      edges {
        node {
          prismicId
          data {
            name
          }
        }
      }
    }
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        description
        siteUrl: url
      }
    }
  }
`
