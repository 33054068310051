import Link from 'gatsby-link'
import { em, fluidRange, padding, timingFunctions } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

import { PostExcerpt } from '../../../types'
import { emUnit, percentCol, toUnit } from '../../theme/helpers/grid'
import { configureFontSize } from '../../theme/helpers/typography'
import { getBreakpoint, getColor, getColorPalette } from '../../theme/selectors'
import { Category } from '../Post/components'
import { PostImage, StyledGatsbyImage } from '../PostImage'
import { PostMetadata as Metadata } from '../PostMetadata'
import { WidowlessTitle } from '../text/WidowlessTitle'

interface HorizontalPostFeatureProps {
  className?: string
  post: PostExcerpt
}

const Image = styled(StyledGatsbyImage)`
  width: ${percentCol(6)};
  min-width: ${emUnit(30)};
  margin-right: ${percentCol(1)};
  border-radius: ${emUnit(3)};
  transition: 0.25s ${timingFunctions('easeOutBack')};

  &,
  &::after {
    ${(props) =>
      fluidRange(
        {
          prop: 'border-radius',
          fromSize: emUnit(1),
          toSize: emUnit(3),
        },
        `${em(getBreakpoint('small')(props))}`,
        `${em(getBreakpoint('veryLarge')(props))}`,
      )}
  }
`

const Content = styled.div`
  width: ${percentCol(5)};
  min-width: ${emUnit(20)};
`

const StyledTitle = styled(WidowlessTitle)``

const Title: React.FC<{ children: string }> = function (props) {
  return <StyledTitle breakpoint="small" level="h4" size="md" {...props} />
}

const Button = styled(Link)`
  ${configureFontSize(14, 16)}
  ${padding(emUnit(1, toUnit(14)), emUnit(1.5, toUnit(14)))}
  margin-top: ${emUnit(2)};
  transition: transform 0.25s ease-out;
  display: inline-block;
  background-color: ${getColorPalette('green', 'lightest')};
  border-radius: ${emUnit(0.5, toUnit(14))};

  &:hover {
    background-color: ${getColor('accent')};
    color: white;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  padding-right: ${percentCol(2)};
  perspective-origin: left;
  perspective: 2000px;

  &:hover {
    ${Image} {
      transform: translateZ(100px);
      box-shadow: 0 16px 40px -8px ${getColor('shadow')};
    }
  }
`

export const HorizontalPostFeature: React.FC<HorizontalPostFeatureProps> = function ({
  className,
  post,
}) {
  return (
    <Wrapper className={className}>
      <Link to={`/${post.uid}`}>
        <PostImage image={post.image}>
          {(imageProps) => <Image {...imageProps} />}
        </PostImage>
      </Link>
      <Content>
        <Metadata post={post}>
          <Category>{post.category}</Category>
        </Metadata>
        <Title>{post.title}</Title>
        <Button to={`/${post.uid}`}>Lire d'article</Button>
      </Content>
    </Wrapper>
  )
}
