import { padding } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

import * as Types from '../../../types'
import { percentCol } from '../../theme/helpers/grid'
import { mediaQuery } from '../../theme/selectors'
import { PostMetadata } from '../PostMetadata'
import { AuthorAvatar, Content, Image, Title } from './components'

interface PostProps {
  post: Types.Post
}

const Wrapper = styled.article`
  ${padding(null, percentCol(3), '13.333333333vw')}

  ${mediaQuery('small')`
    ${padding(null, percentCol(4))}
  `}
  
  ${mediaQuery('large')`
    ${padding(null, percentCol(6))}
  `}
`

const Metadata = styled(PostMetadata)`
  text-align: center;
`

export function Post({ post }: PostProps) {
  return (
    <Wrapper>
      <Image image={post.image} />
      <AuthorAvatar />
      <Metadata post={post} />
      <Title>{post.title}</Title>
      <Content dangerouslySetInnerHTML={{ __html: post.content }} />
    </Wrapper>
  )
}
